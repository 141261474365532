<template>
    <div style="max-width: 350px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="120px" class="login-form">
            <el-form-item v-if="comp.aptitude_score>=80" prop="group_id" label="经销商渠道分组">
                <el-select v-model="ruleForm.group_id" placeholder="请选择经销商渠道分组">
                    <el-option v-for="item in tagList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <div v-else class="flex flex-center">
                <div>公司资质完成度低于80%不可申请</div>
            </div>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div v-if="comp.aptitude_score>=80">
                    <el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button>
                </div>
                <div v-if="comp.aptitude_score>=80">
                    <el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button>
                </div>
                <div v-if="comp.aptitude_score<80">
                    <el-button @click="goto('/comp/zizhi')" type="primary">前去完善</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { getMyCompAptitude } from "@/service/company";
    import { listAgencyGroup, applyAgency } from '@/service/white';
    export default {
        props: ['factoryId','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请选择经销商渠道分组', trigger: 'blur' }
                    ],
                },
                // 代理商分组
                tagList:[],
                ruleForm:{
                    factory_id:null,
                    group_id:null,
                },
                comp:{},
            }
        },
        async created() {
            this.comp = await getMyCompAptitude(this.$store.state.loginUser.comp.id);
            this.tagList = await listAgencyGroup({comp_id: this.factoryId});
            this.ruleForm.factory_id = this.factoryId;
            console.log(this.comp);
        },
        mounted() {},
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                applyAgency(this.ruleForm).then(rst => {
                    // this.$emit("success");
                    this.hide();
                    this.$message.success("提交成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    
</style>