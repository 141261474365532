<template>
  <div class="sortNav">
    <!-- <div class="nav_1">
      <div class="_left">
        所有分类
        <a-icon type="right" />
      </div>
      <div class="_center">
        <span
          v-for="(item,index) in tags"
          v-show="(!collapsed && index < 6) || collapsed"
          class="_tag"
          :key="index"
        >
          <div :title="item.value">{{item.title}}：{{item.value}}</div>
          <a @click="closeTag(item)">
            <a-icon type="close" />
          </a>
        </span>
      </div>
      <el-button size="small" class="_btn" @click="collapsed = !collapsed" v-if="tags.length > 6">
        <span v-if="collapsed">收起筛选</span>
        <span v-else>展开筛选</span>
        <a-icon type="up" v-if="collapsed" />
        <a-icon type="down" v-else />
      </el-button>
    </div> -->
    <!-- <div style="padding-top:20px;"></div> -->
    <div class="nav_box">
      <div class="nav_2">
        <div class="_box" v-for="item in tagJson" :key="item.id">
          <span class="fl">{{item.title}}：</span>
          <el-radio-group
            class="radio_g"
            size="small"
            v-if="item.isSingle"
            v-model="item.radio"
            @change="clickRadio($event,item)"
          >
            <template v-for="(bq,index) in item.data">
              <el-radio-button
                v-show="(!item.collapsed && index <7) || item.collapsed"
                :value="bq.value"
                :key="bq.id"
              >{{bq.value}}</el-radio-button>
              <br v-show="index!=0 && (index+1)%7 == 0 && item.collapsed" :key="index+'_br'" />
            </template>
          </el-radio-group>
          <el-checkbox-group v-model="item.checkbox" class="radio_g" v-else>
            <template v-for="(bq,index) in item.data">
              <el-checkbox
                :value="bq.value"
                v-show="(!item.collapsed && index <7) || item.collapsed"
                :key="bq.id"
              >{{bq.value}}</el-checkbox>
              <br v-show="index!=0 && (index+1)%7 == 0 && item.collapsed" :key="index+'_br'" />
            </template>
          </el-checkbox-group>

          <a @click="item.collapsed = !item.collapsed" v-if="item.data.length > 7">
            <span v-if="item.collapsed" style="margin-right:5px">收起</span>
            <span v-else style="margin-right:5px">更多</span>
            <a-icon type="up" v-if="item.collapsed" />
            <a-icon type="down" v-else />
          </a>
          <el-button v-if="!item.hasChildren" size="small" class="_btn" @click="switchType(item)">
            <span v-if="item.isSingle">多选</span>
            <span v-else>单选</span>
          </el-button>

          <div style="text-align: center;margin-top:10px;" v-if="!item.isSingle">
            <el-button
              size="small"
              style="margin-right:20px;"
              type="primary"
              @click="clickCheckbox(item)"
            >提交</el-button>
            <el-button size="small" @click="switchType(item)">取消</el-button>
          </div>
        </div>
      </div>

      <div v-if="showMore" class="nav_3 flex flex-align-center">
        <label>筛选：</label>
        <div class="select-container" v-if="type === '1'|| type === '4'">
          <!-- <el-select style="width:120px;margin-right:10px"
            placeholder="总人数" size="small"
            v-model="more.member"
            @change="handleMoreChange"
            :getPopupContainer="trigger => trigger.parentNode">
            <el-option
              v-for="(item, i) in more.memberList"
              :key="i"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select> -->
          <el-select style="width:120px;"
            placeholder="成立年限" size="small"
            v-model="more.n_reg_date"
            @change="handleMoreChange"
            :getPopupContainer="trigger => trigger.parentNode">
            <el-option
              v-for="(item, i) in more.yearList"
              :key="i"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
        <div v-if="type === '1'">
          <!-- <el-checkbox style="margin-right:30px"
            v-model="more.real_check" 
            @change="handleMoreChange"
            >只看已实地认证</el-checkbox> -->
          <el-checkbox style="margin-right:30px"
            v-model="more.cert_stat" 
            @change="handleMoreChange"
            >只看已认证</el-checkbox>
          <el-checkbox 
            v-model="more.onsale" 
            @change="handleMoreChange"
            >只看有在售商品</el-checkbox>
        </div>
        <div v-if="type === '3'">
          <div class="flex flex-align-center">
            <span style="margin-right: 10px;">价格</span>
            <a-input-number
              @change="handleInput"
              v-model="more.price.min"
              :min="0"
              :precision="0"
              :formatter="value => `￥${value}`"
              size="small"
              style="width: 80px;"
            />
            <span style="margin: 0 10px;">-</span>
            <a-input-number
              @change="handleInput"
              v-model="more.price.max"
              :min="0"
              :precision="0"
              :formatter="value => `￥${value}`"
              size="small"
              style="width: 80px;"
            />
          </div>
        </div>
        <div v-if="type === '4'">
          <el-checkbox v-model="more.onsale" @change="handleMoreChange">只看在售作品</el-checkbox>
          <!-- <el-checkbox v-model="more.n_case" @change="handleMoreChange">只看有案例展示的企业</el-checkbox> -->
        </div>
      </div>
    </div>
    <!-- <div class="total flex">
      共<span>{{total}}</span>个相关工厂
    </div> -->
  </div>
</template>

<script>
import { debounce } from "lodash";
import { getMergeDict } from "@/service/account";
// import {
//   postToGetSortInfo,
//   getProductCondiction
// } from "@/service/portalService";
// import event from "@/event";

export default {
  props: {
    type: String,
    query: Function,
    showMore: Boolean,
    total:Number,
  },
  data() {
    return {
      collapsed: false,
      tags: [],
      tagJson: [],
      more: {
        member: null,
        memberList:[],
        n_reg_date: null,
        yearList: [],
        cert_stat:false,
        onsale:false,
      }
    };
  },
  watch: {
    tags(e) {
      if (this.query) {
        this.query(e, this.showMore ? this.more : undefined);
      }
    },
    type() {
      this.getInfo();
    }
  },
  async created() {
    this.handleInput = debounce(this.handleMoreChange, 1000);
    this.more.yearList = [{id:'',name:'全部'}].concat((await getMergeDict({k:'n_reg_date'})).n_reg_date);
    this.more.yearList
    console.log(this.more);
  },
  mounted() {
    this.getInfo();
    // this.subscription = event.subscribe(data => {
    //   if (data.topic === "UPDATE_TOTAL") {
    //     this.total = data.payload;
    //   }
    // });
  },
  // beforeDestroy() {
  //   this.subscription.unsubscribe();
  // },
  methods: {
    handleInput() {},
    getInfo() {
      if (this.type === "1") {
        //云工厂
        // postToPortalServer("/factory-list-filter").then(res => {
        //   if (res.errcode === 0) {
        //     for (let i = 0; i < 3; i++) {
        //       let title = "";
        //       let data = [];
        //       if (i === 0) {
        //         title = "工艺能力";
        //         data = res.result.gynl.map(n => {
        //           return {
        //             id: n.k,
        //             value: n.v
        //           };
        //         });
        //       } else if (i === 1) {
        //         title = "生产属性";
        //         data = res.result.jgsx.map(n => {
        //           return {
        //             id: n.k,
        //             value: n.v
        //           };
        //         });
        //       } else {
        //         title = "木材种类";
        //         data = res.result.mczl.map(n => {
        //           return {
        //             id: n.k,
        //             value: n.v
        //           };
        //         });
        //       }
        //       this.tagJson.push({
        //         collapsed: false,
        //         isSingle: true,
        //         data,
        //         title
        //       });
        //     }
        //   }
        // });
        this.query([], this.more);
      } else if (this.type === "sjgc") {
        //设计工厂
        // postToPortalServer("/design-comp-list-filter").then(res => {});
      } else if (this.type === "3") {
        // 产业集市
        // getProductCondiction().then(res => {
        //   const { data: arr } = res;
        //   this.tagJson = arr;
        //   this.tagJson.map(n => {
        //     if (n.hasChildren) {
        //       n.data.map(m => {
        //         m.children.data.map(j => {
        //           j.pid = m.id;
        //         });
        //       });
        //     }
        //   });
        // });
        this.query([], this.more);
      } else {
        // postToGetSortInfo("/api/homepage/HomePage/getInfo", {
        //   type: this.type
        // }).then(res => {
        //   if (res[0]) {
        //     this.tagJson = JSON.parse(res[0].option);
        //     console.log(this.tagJson);
        //   } else {
        //     this.cc = [];
        //   }
        // });
      }
    },
    clickRadio(e, item) {
      if (item.hasChildren) {
        let index = this.tagJson.indexOf(item);
        let children = item.data.filter(item => item.value == e.target.value)[0]
          .children;
        children.parent = item.title;

        let nextNode = this.tagJson[index + 1];
        if (nextNode && nextNode.parent == item.title) {
          this.closeChildren(item);
        }
        this.tagJson.splice(index + 1, 0, children);
      }

      item.radio = e.target.value;
      if (item.checkbox && item.checkbox.length > 0) {
        item.checkbox = [];
      }
      const title = item.title;
      this.tags = this.tags.filter(e => e.title.indexOf(title) == -1);
      if (item.parent) {
        let parent = this.tags.filter(e => e.title == item.parent)[0];
        this.tags.splice(this.tags.indexOf(parent) + 1, 0, {
          title: title,
          value: e.target.value,
          isRadio: true,
          id: item.data.filter(n => n.value === e.target.value)[0].id,
          pid: item.data.filter(n => n.value === e.target.value)[0].pid
        });
      } else {
        this.tags.push({
          title: title,
          value: e.target.value,
          isRadio: true,
          id: item.data.filter(n => n.value === e.target.value)[0].id,
          pid: item.data.filter(n => n.value === e.target.value)[0].pid
        });
      }
    },
    clickCheckbox(item) {
      if (item.checkbox && item.checkbox.length > 0) {
        item.radio = null;
        const title = item.title;
        this.tags = this.tags.filter(e => e.title.indexOf(title) == -1);
        console.log(item);
        this.tags.push({
          title: title,
          value: item.checkbox.join(","),
          pid: item.data[0].pid,
          id: item.checkbox
            .map(n => {
              return item.data.filter(m => m.value === n)[0].id;
            })
            .join(",")
        });
      }
    },
    switchType(item) {
      if (!item.isSingle) {
        let tag = this.tags.filter(f => f.title == item.title)[0];
        if (tag && tag.isRadio) {
          item.checkbox = [];
        }
      }
      item.isSingle = !item.isSingle;
    },

    closeTag(item) {
      let data = this.tagJson.filter(f => f.title == item.title)[0];
      this.closeChildren(data);
    },
    closeChildren(data) {
      if (data.radio) {
        data.radio = null;
      }
      if (data.checkbox) {
        data.checkbox = [];
      }
      data.isSingle = true;
      this.tags = this.tags.filter(f => f.title != data.title);

      let child = this.tagJson.filter(f => f.parent == data.title)[0];
      if (child) {
        this.closeChildren(child);
        this.tagJson = this.tagJson.filter(f => f.parent != data.title);
      }
    },
    handleMoreChange(e) {
      if (this.query) {
        this.query(this.tags, this.more);
      }
    },
    getPopupContainer() {
      return document.querySelector(".layout");
    }
  }
};
</script>
<style scoped>
.nav_3 >>> .ant-input {
  height: 30px;
}
</style>
<style lang="less" scoped>
.sortNav {
  height: auto;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  // margin-bottom: 24px;
  // padding: 16px 0;
  font-size: 14px;
  .nav_1 {
    // width: 1200px;
    // padding-left: 40px;
    margin: auto;
    padding: 15px 0;

    ._left {
      float: left;
      margin-top: 3px;
    }

    ._center {
      display: inline-block;
      width: 980px;
      min-height: 22px;

      ._tag:nth-child(n + 7) {
        margin-top: 5px;
      }

      ._tag {
        margin: 0 8px 0 8px;
        background: #fafafa;
        display: inline-block;
        padding: 0 6px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        line-height: 20px;
        font-size: 12px;

        div {
          width: 120px;
          user-select: none;
          float: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        a {
          color: rgba(0, 0, 0, 0.45);
          font-size: 10px;
          margin-left: 3px;
          float: right;
        }

        a:hover {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    ._btn,
    ._btn:active {
      background: #e6e6e6;
      border: 1px solid rgba(230, 230, 230, 1);
      color: rgba(128, 128, 128, 1);
      float: right;
    }
  }
  .nav_box {
    padding: 20px 0;
    // border: 1px solid #E6E6E6;
  }

  .nav_2 {
    // border: 1px solid rgba(204, 204, 204, 1);
    // width: 1200px;
    // margin: 15px auto 8px auto;
    // padding: 0 8px;
    // border-radius: 4px;

    ._box:last-child {
      border-bottom: none;
    }

    ._box {
      padding: 14px 20px 14px 0px;
      border-bottom: 1px dashed rgba(204, 204, 204, 1);

      .fl {
        padding-top: 5px;
        float: left;
        text-align: right;
        min-width: 80px;
      }

      .ant-radio-button-wrapper {
        border: none;
        outline: none;
        margin: 0 15px 5px 15px;
        padding-left: 0;
      }

      .ant-radio-button-wrapper-checked {
        box-shadow: none;
      }

      .ant-radio-button-wrapper-checked::before {
        background: white !important;
      }

      .ant-radio-button-wrapper::before {
        background: white;
      }

      .ant-checkbox-wrapper {
        margin: 0 15px 5px 15px;
      }

      .radio_g {
        max-width: 950px;
      }

      ._btn {
        float: right;
        margin-right: 16px;
      }

      a {
        float: right;
        user-select: none;
        padding-top: 5px;
      }
    }
  }

  .nav_3 {
    // width: 1200px;
    margin: 0 auto;
    height: 48px;
    padding: 0 16px;
    // background: #f5f5f5;
    // border: 1px solid #ccc;
    border-radius: 4px;
    // margin-top: 16px;
    label {
      // min-width: 88px;
      text-align: right;
      margin-right: 10px;
    }

    .select-container {
      margin-right: 30px;
    }

    .ant-select {
      width: 120px;
      margin-left: 32px;

      &:first-child {
        margin-left: 0;
      }
    }

    .ant-checkbox-wrapper {
      width: auto;
    }
  }

  .total {
    justify-content: flex-end;
    margin: 8px 0;
    padding-right: 24px;

    span {
      color: #ee1818;
      margin: 0 2px;
    }
  }
}
</style>